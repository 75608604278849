import axios from "axios";
import { rootStore } from "./index";
import { logout } from "./user.actions";

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  responseType: "json",
});

export const sso = axios.create({
  baseURL: process.env.REACT_APP_URL_SSO + '/api/v1/auth',
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  responseType: "json",
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      !(
        error.request.responseURL.endsWith("/logout") ||
        error.request.responseURL.endsWith("/login") ||
        error.request.responseURL.endsWith("/refresh")
      )
    ) {
      rootStore.dispatch(logout(true));
    }
    throw error;
  },
);

const methods = {
  instance,
  sso,

  getUserInfo: (userSchool) => {
    return instance.get(`/${userSchool}/userInfo`);
  },

  getUserNotifications: (school) => instance.get(`/${school}/notifications`),

  login: (username, password) =>
    instance.post("/login", {
      username,
      password,
    }),

  loginSso: (username, password) =>
    sso.post("/login", {
      username,
      password,
    }),

  loginSsoGoogle: () => sso.get('/google/auth').then(response => response.data).then(data => {
    window.location.href = data.url;
  }),

  callbackSso: (queryString) => {
    return sso.get(`/google/callback?${queryString}`)
      .then(response => response)
      .catch(error => {
        console.error('Erro ao processar o login do Google:', error);
        throw error;
      });
  },  

  refresh_token: (token) => {
    return instance.post("/refresh", { refresh_token: token });
  },

  refresh_tokenSso: (token) => {
    return sso.post("/refresh", { refresh_token: token });
  },

  logout: () => instance.post("/logout"),

  logoutSso: () => sso.post("/logout"),

  vincularEmail: (email, email_confirmation) => {
    return sso.post("/assign_email", { email: email, email_confirmation: email_confirmation });
  },

  uploadImage: (image) => {
    const formData = new FormData();
    formData.append("file", image);
    return instance.post("/upload-public", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "text/html",
      },
      responseType: "text",
    });
  },

  getSchoolList: () => instance.get("/userSchools"),
  getSchoolTeachers: (userSchool) => instance.get(`/${userSchool}/teachers`),
  searchExercisesList: (
    page,
    title,
    subjects,
    visibility,
    searchType,
    userSchool,
  ) =>
    instance.get(`/${userSchool}/searchExercisesList`, {
      params: {
        page,
        title,
        subject:
          subjects && subjects.join("+").toUpperCase() !== ""
            ? subjects.join("+").toUpperCase()
            : undefined,
        public: searchType === "Exercise" ? visibility === "public" : undefined,
        simulation:
          searchType === "Simulation"
            ? visibility === "private"
              ? "school"
              : "institution"
            : undefined,
      },
    }),

  searchExercises: (page, title, subject, visibility, userSchool) =>
    instance.get(`/${userSchool}/searchExercise`, {
      params: {
        page,
        title,
        subject,
        public: visibility === "public",
      },
    }),

  deleteExerciseList: (id, userSchool) =>
    instance.delete(`/${userSchool}/exerciseList/${id}`),

  undeleteExerciseList: (id, userSchool) =>
    instance.put(`/${userSchool}/exerciseList/${id}/recover`),

  deleteExercise: (id, userSchool) =>
    instance.delete(`/${userSchool}/exercise/${id}`),

  undeleteExercise: (id, userSchool) =>
    instance.put(`/${userSchool}/exercise/${id}/recover`),

  getDefaultSubjects: () => {
    return instance.get("/defaultSubjects").then((response) => response.data);
  },

  getExercise: (id, userSchool) =>
    instance.get(`/${userSchool}/exercise/${id}`),

  searchMyLearningRoadmap: (page, title, subject, userSchool) =>
    instance.get(`/${userSchool}/searchMyLearningRoadmap`, {
      params: {
        page,
        title,
        subject,
      },
    }),

  searchPublicLearningRoadmap: (page, title, subject, userSchool) =>
    instance.get(`/${userSchool}/searchPublicLearningRoadmap`, {
      params: {
        page,
        title,
        subject,
      },
    }),

  searchAssignedLearningRoadmap: (page, title, subject, status, userSchool) =>
    instance.get(`/${userSchool}/searchAssignedLearningRoadmap`, {
      params: {
        page,
        title,
        subject,
        status,
      },
    }),

  deleteLearningRoadmap: (id, userSchool) =>
    instance.delete(`/${userSchool}/learningRoadmap/${id}`),

  undeleteLearningRoadmap: (id, userSchool) =>
    instance.put(`/${userSchool}/learningRoadmap/${id}/recover`),

  getRoadmap: (id, userSchool) =>
    instance.get(`/${userSchool}/learningRoadmap/${id}`),
  importRoadmap: (id, userSchool) =>
    instance.post(`/${userSchool}/learningRoadmap/${id}/import`),

  getRoadmapAllocationGroups: (id, userSchool) =>
    instance.get(`/${userSchool}/learningRoadmap/${id}/group`),
  getRoadmapAllocationGroup: (roadmapId, groupId, userSchool, page, perPage) =>
    instance.get(
      `/${userSchool}/learningRoadmap/${roadmapId}/group/${groupId}/allocation`,
      { params: { page, perPage } },
    ),

  createRoadmap: (userSchool, title, description, level, subject, visibility) =>
    instance.post(`/${userSchool}/learningRoadmap`, {
      title,
      description,
      level,
      subjects: subject,
      visibility,
    }),

  saveRoadmap: (
    id,
    userSchool,
    title,
    description,
    level,
    subject,
    visibility,
    editing,
  ) =>
    instance.put(`/${userSchool}/learningRoadmap/${id}`, {
      title,
      description,
      level,
      subjects: subject,
      visibility,
      is_editing: editing,
    }),

  updateRoadmapCoauthors: (id, userSchool, coauthorsToAdd, coauthorsToRemove) =>
    instance.put(`/${userSchool}/learningRoadmap/${id}/editCollaborators`, {
      add: coauthorsToAdd,
      remove: coauthorsToRemove,
    }),

  saveAllocationGroup: (
    roadmapId,
    userSchool,
    allocationGroupId,
    taskTitle,
    taskStart,
    taskEnd,
  ) =>
    instance.put(
      `/${userSchool}/learningRoadmap/${roadmapId}/group/${allocationGroupId}`,
      {
        title: taskTitle,
        date_start: new Date(taskStart).toISOString(),
        date_end: !!taskEnd ? new Date(taskEnd).toISOString() : null,
      },
    ),

  getRoadmapSteps: (id, userSchool) =>
    instance.get(`/${userSchool}/learningRoadmap/${id}/step`),
  saveRoadmapSteps: (id, userSchool, steps, timestamp) =>
    instance.post(`/${userSchool}/learningRoadmap/${id}/steps`, {
      steps,
      timestamp,
    }),
  // teacher route
  getRoadmapAllocation: (
    roadmapId,
    userSchool,
    allocationGroupId,
    allocationId,
  ) =>
    instance.get(
      `/${userSchool}/learningRoadmap/${roadmapId}/group/${allocationGroupId}/allocation/${allocationId}/answer`,
    ),

  // student route
  getAllocationRoadmap: (allocationId, userSchool) =>
    instance.get(`/${userSchool}/learningRoadmapAllocation/${allocationId}`),
  getAllocationSteps: (allocationId, userSchool) =>
    instance.get(
      `/${userSchool}/learningRoadmapAllocation/${allocationId}/step`,
    ),
  getAllocationAnswers: (allocationId, userSchool) =>
    instance.get(
      `/${userSchool}/learningRoadmapAllocation/${allocationId}/answer`,
    ),
  saveDraft: (allocationId, userSchool, stepId, content) =>
    instance.post(
      `/${userSchool}/learningRoadmapAllocation/${allocationId}/step/${stepId}/draft`,
      { content },
    ),
  submitAnswers: (allocationId, userSchool, steps) =>
    instance.post(
      `/${userSchool}/learningRoadmapAllocation/${allocationId}/answer`,
      { steps },
    ),

  saveFeedback: (
    feedback,
    answerId,
    stepId,
    allocationId,
    allocationGroupId,
    roadmapId,
    userSchool,
  ) =>
    instance.post(
      `/${userSchool}/learningRoadmap/${roadmapId}/group/${allocationGroupId}/allocation/${allocationId}/step/${stepId}/answer/${answerId}/feedback`,
      { feedback },
    ),

  acceptAnswer: (
    answerId,
    stepId,
    allocationId,
    allocationGroupId,
    roadmapId,
    userSchool,
  ) =>
    instance.put(
      `/${userSchool}/learningRoadmap/${roadmapId}/group/${allocationGroupId}/allocation/${allocationId}/step/${stepId}/answer/${answerId}/accept`,
    ),

  sendBackToStudent: (allocationId, allocationGroupId, roadmapId, userSchool) =>
    instance.put(
      `/${userSchool}/learningRoadmap/${roadmapId}/group/${allocationGroupId}/allocation/${allocationId}/sendBack`,
    ),
  finishAllocation: (allocationId, allocationGroupId, roadmapId, userSchool) =>
    instance.put(
      `/${userSchool}/learningRoadmap/${roadmapId}/group/${allocationGroupId}/allocation/${allocationId}/accept`,
    ),

  getSchoolStudents: (userSchool) => instance.get(`/${userSchool}/students`),

  getSchoolClasses: (userSchool) => instance.get(`/${userSchool}/classes`),

  getClassStudents: (userSchool, classId) =>
    instance.get(`/${userSchool}/classes/${classId}/students`),

  createAllocationGroup: (
    title,
    dateStart,
    dateEnd,
    students,
    roadmapId,
    userSchool,
  ) =>
    instance.post(`/${userSchool}/learningRoadmap/${roadmapId}/group`, {
      title,
      date_start: dateStart.toISOString(),
      date_end: dateEnd ? dateEnd.toISOString() : undefined,
      students,
    }),

  updateAllocationGroup: (
    title,
    dateStart,
    dateEnd,
    studentsToAdd,
    studentsToRemove,
    groupId,
    roadmapId,
    userSchool,
  ) =>
    instance.put(
      `/${userSchool}/learningRoadmap/${roadmapId}/group/${groupId}`,
      {
        title,
        date_start: dateStart.toISOString(),
        date_end: dateEnd ? dateEnd.toISOString() : undefined,
        add_students: studentsToAdd,
        remove_students: studentsToRemove,
      },
    ),

  /**
   * WEBQUEST METHODS
   */

  getWQActivitiesAsTeacher: (webquest, userSchool) =>
    instance.get(`${userSchool}/webquest/${webquest}/activity`),
  getWQActivitiesAsStudent: (
    page,
    perPage,
    title,
    subject,
    teacher,
    userSchool,
  ) =>
    instance.get(`${userSchool}/wqactivity`, {
      params: {
        page,
        perPage,
        title,
        subject,
        teacher,
      },
    }),
  getMyWQs: (page, perPage, title, subject, level, deleted, userSchool) =>
    instance.get(`${userSchool}/webquest`, {
      params: {
        page,
        perPage,
        title,
        subject,
        level,
        deleted,
      },
    }),
  getPublicWQs: (
    page,
    perPage,
    title,
    subject,
    level,
    author,
    visibility,
    userSchool,
  ) =>
    instance.get(`${userSchool}/publicWebquest`, {
      params: {
        page,
        perPage,
        title,
        subject,
        level,
        author,
        visibility,
      },
    }),
  getWebQuest: (id, userSchool) => instance.get(`${userSchool}/webquest/${id}`),
  getWQActivityAsStudent: (id, userSchool) =>
    instance.get(`${userSchool}/wqactivity/${id}`),
  getWQActivityAsTeacher: (id, activityId, userSchool) =>
    instance.get(`${userSchool}/webquest/${id}/activity/${activityId}`),
  createWQActivity: (id, userSchool, body) =>
    instance.post(`${userSchool}/webquest/${id}/activity`, body),
  updateWQActivity: (id, activityId, userSchool, body) =>
    instance.put(`${userSchool}/webquest/${id}/activity/${activityId}`, body),
  deleteWQActivity: (school, id, activityId) =>
    instance.delete(`${school}/webquest/${id}/activity/${activityId}`),
  importWebQuest: (id, userSchool) =>
    instance.post(`${userSchool}/webquest/${id}/import`),
  createWebQuest: (
    userSchool,
    {
      title,
      level,
      subjects,
      content = {
        introduction: null,
        task: null,
        process: null,
        evaluation: null,
        conclusion: null,
      },
      visibility,
      isEditing,
    },
  ) =>
    instance.post(`${userSchool}/webquest`, {
      title,
      education_level: level,
      subjects,
      content,
      visibility,
      is_editing:
        isEditing === true || isEditing === 1 || isEditing === undefined,
    }),
  updateWebQuest: (
    id,
    userSchool,
    { title, level, subjects, content, visibility, isEditing },
  ) =>
    instance.put(`${userSchool}/webquest/${id}`, {
      title,
      education_level: level,
      subjects,
      content,
      visibility,
      is_editing: isEditing === true || isEditing === 1,
    }),
  deleteWebQuest: (id, userSchool) =>
    instance.delete(`${userSchool}/webquest/${id}`),
  recoverWebQuest: (id, userSchool) =>
    instance.put(`${userSchool}/webquest/${id}/recover`),

  /**
   * CREATION TOOLS METHODS
   * @see {@link https://ferramentasdecriacao.docs.apiary.io/}
   */

  // Criar Atividade (Professor)
  createCreationActivityTeacher: (
    userSchool,
    content_type,
    { title, date_start, date_end, subjects, allow_group, education_level },
  ) =>
    instance.post(`${userSchool}/creativeActivity`, {
      title,
      date_start,
      date_end,
      subjects,
      education_level,
      allow_group,
      content_type,
    }),

  // Soft-delete activity
  deleteCreationActivityTeacher: (userSchool, id) =>
    instance.delete(`${userSchool}/creativeActivity/${id}`),

  // Recover deleted activity
  restoreCreationActivityTeacher: (userSchool, id) =>
    instance.put(`${userSchool}/creativeActivity/${id}/recover`),

  // Listar Atividades (Professor)
  getTeacherCreationActivities: (
    userSchool,
    type,
    { page, perPage, title, subject },
  ) =>
    instance.get(`${userSchool}/listCreativeActivity`, {
      params: {
        page,
        perPage,
        title,
        subject,
        type,
      },
    }),

  // Listar Alocações (Aluno)
  getStudentCreationActivities: (
    userSchool,
    type,
    { currentPage: page, perPage, title, subject, teacher, status },
  ) =>
    instance.get(`${userSchool}/listCreativeAllocation`, {
      params: { page, perPage, title, subject, teacher, type, status },
    }),

  // Listar Conteúdos Avulsos (Aluno/Professor)
  getMyCreationContents: (
    userSchool,
    type,
    { page, perPage, title, subject, deleted },
  ) =>
    instance.get(`${userSchool}/listCreativeContent`, {
      params: {
        page,
        perPage,
        title,
        subject,
        type,
        deleted,
      },
    }),

  // Listar Revisões de Conteúdos (Professor)
  getRevisionsTeacher: (
    userSchool,
    type,
    { page, perPage, title, subject, student, status },
  ) =>
    instance.get(`${userSchool}/listCreativeContentReviews`, {
      params: {
        page,
        perPage,
        title,
        subject,
        type,
        student,
        status,
      },
    }),

  // Listar Conteúdos Públicos (Aluno/Professor)
  getPublicCreationContents: (
    userSchool,
    type,
    { page, perPage, title, subject, author, level },
  ) =>
    instance.get(`${userSchool}/listPublicCreativeContent`, {
      params: {
        page,
        perPage,
        title,
        subject,
        author,
        type,
        education_level: level,
      },
    }),

  // Listar Revisões de Conteúdos (Professor)
  getCreationReviews: (
    userSchool,
    type,
    { page, perPage, title, subject, student, status },
  ) =>
    instance.get(`${userSchool}/listCreativeContentReviews`, {
      params: { page, perPage, title, subject, student, type, status },
    }),
  // Recuperar Atividade existente
  getCreationActivity: (userSchool, activityId) =>
    instance.get(`${userSchool}/creativeActivity/${activityId}`),

  // Editar Atividade existente
  editCreativeActivity: (
    userSchool,
    activityId,
    {
      title,
      contentType,
      dateEnd,
      dateStart,
      allowGroup,
      subjects,
      level,
      groups,
      newGroups,
      addStudents,
      removeStudents,
      description,
    },
  ) =>
    instance.put(`${userSchool}/creativeActivity/${activityId}`, {
      title,
      description,
      date_start: dateStart,
      date_end: dateEnd,
      allow_group: allowGroup,
      subjects: subjects,
      content_type: contentType,
      education_level: level,
      add_students: addStudents,
      remove_students: removeStudents,
      groups,
      new_groups: newGroups,
    }),
  // Visualizar Conteúdo Avulso (Aluno/Professor)
  getContent: (userSchool, contentId) =>
    instance.get(`${userSchool}/creativeContent/${contentId}`),

  // Visualizar Conteúdo de uma Alocação de uma Atividade (Professor)
  getAllocationContentAsTeacher: (userSchool, activityId, allocationId) =>
    instance.get(
      `${userSchool}/creativeActivity/${activityId}/allocation/${allocationId}`,
    ),

  // Visualizar Alocação e Conteúdo (Aluno)
  getAllocationContentAsStudent: (userSchool, allocationId) =>
    instance.get(`${userSchool}/creativeAllocation/${allocationId}`),

  // Editar Conteúdo de uma Alocação (Aluno)
  updateCreativeActivityAsStudent: (
    userSchool,
    allocationId,
    { title, thumbnail, content },
  ) =>
    instance.put(`${userSchool}/creativeAllocation/${allocationId}`, {
      title,
      thumbnail,
      content,
    }),

  // Salvar Devolutiva/Correção/Edição do conteúdo de uma atividade (Professor)
  updateCreativeActivityAsTeacher: (
    userSchool,
    activityId,
    allocationId,
    { content, thumbnail, feedback },
  ) =>
    instance.put(
      `${userSchool}/creativeActivity/${activityId}/allocation/${allocationId}/feedback`,
      { content, thumbnail, feedback },
    ),

  // Editar Conteúdo Avulso (Aluno/Professor)
  updateStandaloneCreativeContent: (
    userSchool,
    contentId,
    {
      title,
      visibility,
      contentType,
      subjects,
      educationLevel,
      content,
      thumbnail,
    },
  ) =>
    instance.put(`${userSchool}/creativeContent/${contentId}`, {
      title,
      content,
      thumbnail,
      visibility,
      content_type: contentType,
      subjects,
      education_level: educationLevel,
    }),

  // Administrativo / Salvar Devolutiva/Correção/Edição de conteúdo avulso (Professor)
  updateStandaloneCreativeFeedback: (
    userSchool,
    contentId,
    { content, thumbnail, feedback },
  ) =>
    instance.put(`${userSchool}/creativeContent/${contentId}/feedback`, {
      content,
      thumbnail,
      feedback,
    }),

  //

  // Dar Lock no Conteúdo da Alocação em Grupo (Aluno)
  lockCreativeContent: (userSchool, allocationId) =>
    instance.put(`${userSchool}/creativeAllocation/${allocationId}/lock`),

  // Dar Unlock no Conteúdo da Alocação em Grupo (Aluno)
  unlockCreativeContent: (userSchool, allocationId) =>
    instance.put(`${userSchool}/creativeAllocation/${allocationId}/unlock`),

  // Criar conteúdo avulso (Aluno/Professor)
  createNewStandaloneCreativeContent: (
    userSchool,
    { title, visibility, contentType, subjects, level, content, thumbnail },
  ) =>
    instance.post(`${userSchool}/creativeContent`, {
      title,
      visibility,
      content_type: contentType,
      subjects,
      education_level: level,
      content,
      thumbnail,
    }),

  // Enviar Conteúdo da Alocação para correção (Aluno)
  sendCreativeActivityForReview: (
    userSchool,
    allocationId,
    { content, thumbnail, title },
  ) =>
    instance.put(`${userSchool}/creativeAllocation/${allocationId}/submit`, {
      content,
      thumbnail,
      title,
    }),

  // Enviar Conteudo Avulso para Revisão(Aluno)
  sendStandaloneCreativeContentForReview: (
    userSchool,
    contentId,
    { content, thumbnail, teacherId, title, subjects, educationLevel },
  ) =>
    instance.put(`${userSchool}/creativeContent/${contentId}/verify`, {
      content,
      thumbnail,
      teacher_id: teacherId,
      title,
      subjects,
      education_level: educationLevel,
    }),
  // Devolver Conteudo Avulso do Aluno com Devolutiva/Correção (Professor)
  sendBackStandaloneCreativeContent: (
    userSchool,
    contentId,
    { content, thumbnail, feedback },
  ) =>
    instance.put(`${userSchool}/creativeContent/${contentId}/sendback`, {
      content,
      thumbnail,
      feedback,
    }),
  // Aceitar Conteudo Avulso do Aluno(Professor)
  acceptStandaloneCreativeContent: (
    userSchool,
    contentId,
    { content, thumbnail, feedback, visibility },
  ) =>
    instance.put(`${userSchool}/creativeContent/${contentId}/accept`, {
      content,
      thumbnail,
      feedback,
      visibility,
    }),
  // Publicar Conteudo Avulso do Aluno(Professor)
  publishStandaloneCreativeContent: (
    userSchool,
    contentId,
    {
      visibility,
      content,
      thumbnail,
      feedback,
      title,
      subjects,
      educationLevel,
    },
  ) =>
    instance.put(`${userSchool}/creativeContent/${contentId}/publish`, {
      visibility,
      content,
      thumbnail,
      feedback,
      title,
      subjects,
      education_level: educationLevel,
    }),

  // Devolver Atividade ao Aluno com Devolutiva/Correção/Edição (Professor)
  sendBackActivityCreativeContent: (
    userSchool,
    activityId,
    contentId,
    { content, thumbnail, feedback },
  ) =>
    instance.put(
      `${userSchool}/creativeActivity/${activityId}/allocation/${contentId}/sendback`,
      {
        content,
        thumbnail,
        feedback,
      },
    ),
  // Aceitar Conteudo Alocação(Professor)
  acceptActivityCreativeContent: (
    userSchool,
    activityId,
    contentId,
    { content, thumbnail, feedback },
  ) =>
    instance.put(
      `${userSchool}/creativeActivity/${activityId}/allocation/${contentId}/accept`,
      {
        content,
        thumbnail,
        feedback,
      },
    ),
  // Publicar Conteudo Aprovado de uma Atividade do Aluno (Professor)
  publishActivityCreativeContent: (
    userSchool,
    activityId,
    contentId,
    { visibility, content, thumbnail, feedback },
  ) =>
    instance.put(
      `${userSchool}/creativeActivity/${activityId}/allocation/${contentId}/publish`,
      {
        visibility,
        content,
        thumbnail,
        feedback,
      },
    ),
  deleteCreativeContent: (userSchool, contentId) =>
    instance.delete(`${userSchool}/creativeContent/${contentId}`),
  recoverCreativeContent: (userSchool, contentId) =>
    instance.put(`${userSchool}/creativeContent/${contentId}/recover`),
  // listagem historico
  // Recuperar o histórico de edições de um conteúdo avulso (Professor/aluno)
  getStandaloneContentHistory: (userSchool, contentId) =>
    instance.get(`${userSchool}/creativeContent/${contentId}/history`),
  //Recuperar Histórico de Edições de um Conteúdo de uma Atividade (Professor)
  getActivityContentHistoryAsTeacher: (userSchool, activityId, contentId) =>
    instance.get(
      `${userSchool}/creativeActivity/${activityId}/allocation/${contentId}/history`,
    ),
  //Recuperar Histórico de Edições de um Conteúdo de uma Atividade (Aluno)
  getActivityContentHistoryAsStudent: (userSchool, activityId) =>
    instance.get(`${userSchool}/creativeAllocation/${activityId}/history`),

  // Recuperar uma Versão do Histórico de Edições de um Conteúdo Avulso (Aluno/Professor)
  getCreativeContentOlderVersion: (userSchool, contentId, historyId) =>
    instance.get(
      `${userSchool}/creativeContent/${contentId}/history/${historyId}`,
    ),

  // Recuperar uma Versão do Histórico de Edições de um Conteúdo de uma Atividade (Professor)
  getActivityContentOlderVersionAsTeacher: (
    userSchool,
    activityId,
    contentId,
    historyId,
  ) =>
    instance.get(
      `${userSchool}/creativeActivity/${activityId}/allocation/${contentId}/history/${historyId}`,
    ),
  // Recuperar uma Versão do Histórico de Edições de um Conteúdo de uma Alocação (Aluno)
  getActivityContentOlderVersionAsStudent: (
    userSchool,
    activityId,
    historyId,
  ) =>
    instance.get(
      `${userSchool}/creativeAllocation/${activityId}/history/${historyId}`,
    ),
};
export default methods;
