import classnames from "classnames";
import ClickContainer from "components/ClickContainer";
import { A11yContext } from "contexts/A11y.context";
import { ThemeContext } from "contexts/Theme.context";
import { useContext } from "react";
import Header from "./components/Menu";
import SystemStatus from "./components/SystemStatus";
import { F1Theme } from "./components/Themes";
import styles from "./Portal.module.scss";
import { ReactComponent as WaveShape } from "./waveshape.svg";

const Footer = () => "Clickideia Tecnologia Educacional © 2002 - 2022";

const Portal = ({ children }) => {
  const { nightMode, reducedMotion } = useContext(A11yContext);
  const { mainBackground, collapsed, theme } = useContext(ThemeContext);

  return (
    <div
      className={classnames(styles.templateGrid, {
        nightMode,
        reducedMotion,
        [styles.collapsed]: !!collapsed,
        [styles.f1]: theme === "anosiniciais",
      })}
    >
      <div className={styles.wavesContainer}>
        <WaveShape />
      </div>
      <div className={styles.shape}>
        {theme === "anosiniciais" && <F1Theme />}
      </div>
      <Header />
      <ClickContainer className={styles.mainWrap}>
        <main
          className={classnames(styles.main, {
            [styles.noBackground]: !mainBackground,
          })}
        >
          {children}
        </main>
      </ClickContainer>
      {!collapsed && (
        <div className={styles.footer}>
          <Footer />
        </div>
      )}
      <SystemStatus />
    </div>
  );
};

export default Portal;
