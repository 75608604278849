import { FeedbackContext } from "components/Feedback";
import { api } from "core";

const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const vincularEmail = (email, email_confirmation) => async () => {
  if (!email || email.trim() === "" || !email_confirmation || email_confirmation.trim() === "") {
    FeedbackContext.addFeedback("Preencha ambos os campos de e-mail.", "error");
    throw new Error("Preencha ambos os campos de e-mail.");
  }

  if (email !== email_confirmation) {
    FeedbackContext.addFeedback("Os e-mails não coincidem.", "error");
    throw new Error("Os e-mails não coincidem.");
  }

  if (!isValidEmail(email)) {
    FeedbackContext.addFeedback("Digite um e-mail válido.", "error");
    throw new Error("Digite um e-mail válido.");
  }

  try {
    const response = await api.vincularEmail(email, email_confirmation);
    if(response.status === 200){
      FeedbackContext.addFeedback("E-mail vinculado com sucesso.", "success");
    }else{
      FeedbackContext.addFeedback("Ocorreu um erro ao realizar a vinculação.", "error");
    }
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "Erro ao vincular o e-mail.";
    FeedbackContext.addFeedback(errorMessage, "error");
    throw new Error(errorMessage);
  }
};
