/** Formats date as dd/MM/YYYY
 */
export const dateFormat = (date) =>
  `${date.getUTCDate().toLocaleString(undefined, {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}/${(date.getUTCMonth() + 1).toLocaleString(undefined, {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}/${date.getUTCFullYear()}`;

export const dateTimeFormatToUserTimeZone = (date) =>
  `${date.getDate().toLocaleString(undefined, {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}/${(date.getMonth() + 1).toLocaleString(undefined, {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}/${date.getFullYear()} às ${date.getHours().toLocaleString(undefined, {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}:${date.getMinutes().toLocaleString(undefined, {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}:${date.getSeconds().toLocaleString(undefined, {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })}`;

/* New today date at 00:00:00 UTC  */
export const getTodayDateStart = () => {
  const todayDate = new Date();
  todayDate.setHours(0);
  todayDate.setMinutes(0);
  todayDate.setSeconds(0);
  return new Date(
    Date.UTC(
      todayDate.getFullYear(),
      todayDate.getMonth(),
      todayDate.getDate(),
      todayDate.getHours(),
      todayDate.getMinutes(),
      todayDate.getSeconds(),
    ),
  );
};

/* New today date at 23:59:59 UTC  */
export const getTodayDateEnd = () => {
  const todayDate = new Date();
  todayDate.setHours(23);
  todayDate.setMinutes(59);
  todayDate.setSeconds(59);
  return new Date(
    Date.UTC(
      todayDate.getFullYear(),
      todayDate.getMonth(),
      todayDate.getDate(),
      todayDate.getHours(),
      todayDate.getMinutes(),
      todayDate.getSeconds(),
    ),
  );
};

/** Merges 2 objects, ignoring null properties
 */
export const mergeIfNotNull = (left, right) => {
  let ret = {};
  for (const leftEntry of Object.entries(left || {})) {
    if (leftEntry[1] && leftEntry[1] !== null) {
      ret[leftEntry[0]] = leftEntry[1];
    }
  }
  for (const rightEntry of Object.entries(right || {})) {
    if (rightEntry[1] && rightEntry[1] !== null) {
      ret[rightEntry[0]] = rightEntry[1];
    }
  }
  return ret;
};

/** Convert URL query string to object
 */
export const queryToObj = (query) =>
  [...new URLSearchParams(query)].reduce((prev, current) => {
    try {
      prev[current[0]] = JSON.parse(current[1]);
    } catch (error) {
      prev[current[0]] = current[1];
    }
    return prev;
  }, {});

export const objToQuery = (obj) => {
  const retObj = Object.entries(obj).reduce((newObj, current) => {
    if (typeof current[1] === "object" && !Array.isArray(current[1]))
      newObj[current[0]] = JSON.stringify(current[1]);
    else if (current[1] && current[1] !== null) newObj[current[0]] = current[1];

    return newObj;
  }, {});

  return new URLSearchParams(retObj).toString();
};

export const compareObject = (obj1, obj2) => {
  if (
    obj1 === undefined ||
    obj1 === null ||
    obj2 === undefined ||
    obj2 === null
  ) {
    return obj1 === obj2;
  }
  let obj2Cpy = { ...obj2 };
  for (const entry of Object.entries(obj1)) {
    if (entry[1] !== undefined && obj2Cpy[entry[0]] === undefined) return false;
    if (Array.isArray(entry[1]) && !compareArray(entry[1], obj2Cpy[entry[0]]))
      return false;
    if (!Array.isArray(entry[1]) && obj2Cpy[entry[0]] !== entry[1]) {
      return false;
    }
    delete obj2Cpy[entry[0]];
  }
  if (Object.entries(obj2Cpy).length === 0) return true;
  else return false;
};

export const compareArray = (arr1, arr2) => {
  if (
    arr1 === undefined ||
    arr1 === null ||
    arr2 === undefined ||
    arr2 === null
  ) {
    return arr1 === arr2;
  }
  if (arr1.length !== arr2.length) return false;
  let set = new Set(arr2);
  for (const el of arr1) {
    if (!set.has(el)) return false;
    set.delete(el);
  }
  return set.size === 0;
};

export const applyRef = (element, refHolder) => {
  if (refHolder) {
    if (typeof refHolder === "function") {
      refHolder(element);
    } else if (typeof refHolder === "object") {
      refHolder.current = element;
    }
  }
};

export const unescapeHtml = (text) => {
  const el = document.createElement("span");
  el.innerHTML = text;
  return el.innerText;
};

export const getCookie = (name) => {
  var pairs = document.cookie.split("; "),
    count = pairs.length,
    parts;
  while (count--) {
    parts = pairs[count].split("=");
    if (parts[0] === name) return parts[1];
  }
  return false;
};

export const sanitizeWord = (word) => {
  const sanitizingMap = [
    { base: "A", regexp: /[ÁÀÂÃ]/g },
    { base: "E", regexp: /[ÉÊ]/g },
    { base: "I", regexp: /[Í]/g },
    { base: "O", regexp: /[ÓÔÕ]/g },
    { base: "U", regexp: /[Ú]/g },
    { base: "C", regexp: /[Ç]/g },
    { base: "N", regexp: /[Ñ]/g },
    { base: "", regexp: /[ ]/g },
  ];
  // if word is null or undefined, ignore it
  let sanitizedWord = word || "";
  for (const sanitizingElement of sanitizingMap) {
    sanitizedWord = sanitizedWord.replace(
      sanitizingElement.regexp,
      sanitizingElement.base,
    );
  }
  return sanitizedWord;
};

/**
 * @function localeContains
 * @description Check if substring a is contained in b,
 * using localeCompare to search for substring
 * @param {string} haystack - String where search happens
 * @param {string} needle - Substring to be searched
 * @returns {boolean} If substring was found or not
 */
export const localeContains = (haystack, needle) =>
  !!haystack
    .split("")
    .filter(
      (v, i) =>
        haystack
          .slice(i, i + needle.length)
          .localeCompare(needle, "pt-br", { sensitivity: "base" }) === 0,
    ).length;

/**
 *
 * @param {string} name the full name
 * @returns string
 */
export function shortName(name) {
  const firstName = name.replace(/ .+$/g, "");
  const rawLastName = name.replace(/^\w+ /g, "");

  const lastName = rawLastName.replace(/(\w{3,})/g, (match, group1) =>
    group1.search(/^d(?:o|a|e)(?:s|$)$/g) === -1
      ? `${group1.charAt(0)}.`
      : group1,
  );

  return `${firstName} ${lastName}`;
}
