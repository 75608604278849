import ClickButton from "components/ClickButton";
import ClickHeading from "components/ClickHeading";
import ClickLink from "components/ClickLink";
import FormInput from "components/Form/FormInput";
import { ThemeContext } from "contexts/Theme.context";
import { UserContext } from "contexts/User.context";
import { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import "./Login.scss";

const Login = ({
  username,
  password,
  schoolList,
  selectSchool,
  login,
  loginSsoGoogle,
  callbackSso,
  callbackBubble,
  typeUsername,
  typePassword,
  errorMessage,
}) => {
  const { setMainBackground } = useContext(ThemeContext);
  const { id } = useContext(UserContext);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisible = () => setPasswordVisible(!passwordVisible);

  useEffect(() => {
    setMainBackground && setMainBackground(false);
    return () => setMainBackground && setMainBackground(true);
  }, [setMainBackground]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const queryString = urlParams.toString();

    if (urlParams.get('code')) {
      callbackSso(queryString).catch(error => console.error('Erro ao processar o login do Google:', error));
    } else if (urlParams.get('origin') === 'bubble') {
      const access_token = urlParams.get('access_token');
      const refresh_token = urlParams.get('refresh_token');
      const tokens = {
        access_token: access_token,
        refresh_token: refresh_token
      };
      callbackBubble(tokens);
    }
  }, []);

  if (id) return <Redirect to="/" />;

  return (
    <div className="LoginContainer">
      {schoolList && schoolList !== null ? (
        <form>
          <ClickHeading tag="h1">
            Selecione sua <span>escola</span>
          </ClickHeading>
          <FormInput
            type="dropdown"
            value={null}
            data={schoolList.map((school) => ({
              label: school.name,
              value: school.id,
            }))}
            onChange={selectSchool}
          />
        </form>
      ) : (
        <form
          onSubmit={(event) => {
            login(username, password);
            event.preventDefault();
          }}
        >
          <ClickHeading tag="h1">
            Faça seu <span>Login</span>
          </ClickHeading>
          <span className="error">{errorMessage}</span>
          <FormInput
            id="LoginUsername"
            label="Login"
            type="text"
            value={username}
            onChange={typeUsername}
          />
          <div className="passwordInput">
            <FormInput
              id="LoginPassord"
              label="Senha"
              type={passwordVisible ? "text" : "password"}
              value={password}
              onChange={typePassword}
            />
            <ClickButton
              fill="simple"
              className="passwordButton"
              icon={passwordVisible ? "eye-slash" : "eye"}
              label={passwordVisible ? "Esconder senha" : "Mostrar senha"}
              onClick={togglePasswordVisible}
            />
          </div>
          <ClickLink
            className="lostPassword"
            link="/esqueci-minha-senha"
          >
            Esqueci minha senha
          </ClickLink>
          <ClickButton
            type="submit"
            className="loginButton"
            color="success"
            icon="sign-in-alt"
          >
            Login
          </ClickButton>
          <div className="separator"></div>
          <ClickButton
            className="googleLoginButton"
            onClick={loginSsoGoogle}
          >
            <img
              src="https://developers.google.com/identity/images/g-logo.png"
              alt="Google Logo"
              className="googleLogo"
            />
            Google
          </ClickButton>

          <ClickLink
            className="helpAccess"
            target="_blank"
            link="https://ajuda.clickideia.com.br/login"
            rel="noopener noreferrer"
          >
            Ajuda com acesso
          </ClickLink>
        </form>
      )}
    </div>
  );
};

export default Login;
