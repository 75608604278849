import { push, replace } from "connected-react-router";
import { api } from "core";
import { getCookie } from "core/utils";

export const tryToLoadTokens = () => (dispatch) => {
  const old_access_token = getCookie("old_access_token");
  const old_codigo_usuario = getCookie("old_codigo_usuario");
  const localstorage_old_access_token =
    localStorage.getItem("old_access_token");
  const localstorage_old_codigo_usuario =
    localStorage.getItem("old_codigo_usuario");
  if (
    old_access_token &&
    old_codigo_usuario &&
    (old_access_token !== localstorage_old_access_token ||
      old_codigo_usuario !== localstorage_old_codigo_usuario)
  ) {
    dispatch(tryLegacyLogin(old_access_token, old_codigo_usuario));
  } else {
    const tokens = {
      access_token: localStorage.getItem("access_token"),
      refresh_token: localStorage.getItem("refresh_token"),
    };
    if (tokens.access_token !== null && tokens.refresh_token !== null) {
      dispatch(tokenAcquired(tokens.access_token, tokens.refresh_token));
      const userSchool = localStorage.getItem("user.school");
      if (userSchool) {
        dispatch(selectSchool(userSchool));
      } else {
        dispatch(logout());
      }
    }
  }
};

const tryLegacyLogin =
  (old_access_token, old_codigo_usuario) => (dispatch, getState) =>
    api.instance
      .post("/authRedirect", { old_access_token })
      .then((loginResponse) => {
        dispatch(
          tokenAcquired(
            loginResponse.data.access_token,
            loginResponse.data.refresh_token,
          ),
        );
        return api.getSchoolList().then((response) => {
          // TODO: when this user has no school?
          const userSchool =
            response.data && response.data[0] && response.data[0].id;
          if (userSchool) {
            dispatch(selectSchool(userSchool));
            dispatch(replace(getState().view.login.backTo));
          } else {
            dispatch(tryToLoadTokens());
          }
        });
      })
      .catch((err) => {
        dispatch(tryToLoadTokens());
      })
      .finally((payload) => {
        localStorage.setItem("old_access_token", old_access_token);
        localStorage.setItem("old_codigo_usuario", old_codigo_usuario);
      });

export const tokenAcquired = (token, refresh_token) => (dispatch, getState) => {
  // set cookie to get cookie-authenticated endpoints such as images
  const today = new Date();
  const expires = new Date();
  expires.setTime(today.getTime() + 1e3 * 60 * 60 * 24 * 30);
  /** @todo auth cookie-authenticated at localhost/other local domains */
  document.cookie = `bearer_token=${token}; Domain=.clickideia.com.br; expires=${expires.toGMTString()}; path=/; SameSite=Strict`;

  api.instance.defaults.headers.common["Authorization"] = "Bearer " + token;
  localStorage.setItem("access_token", token);
  localStorage.setItem("refresh_token", refresh_token);
  window.clearInterval(getState().user.refreshIntervalId);
  /** @todo deal with refresh long interval in backend (1 month). Maybe, some sw may do te job */
  const intervalId = window.setInterval(
    () => dispatch(refreshToken()),
    60 * 60 * 24 * 30,
  ); // 1 month
  return dispatch({
    type: "user.tokenAcquired",
    payload: { access_token: token, refresh_token: refresh_token, intervalId },
  });
};

export const refreshToken = () => (dispatch, getState) => {
  api
    .refresh_tokenSso(getState().user.refresh_token)
    .then((response) =>
      dispatch(
        tokenAcquired(response.data.access_token, response.data.refresh_token),
      ),
    );
};

export const selectSchool = (schoolId) => (dispatch) => {
  dispatch({
    type: "user.schoolSelected",
    payload: { school: schoolId },
  });
  dispatch(getUserInfo(schoolId));
  dispatch(loadDefaultSubjects());
  dispatch(getSchoolTeachers(schoolId));
};

const getSchoolTeachers = (schoolId) => (dispatch) => {
  return api.getSchoolTeachers(schoolId).then(({ data }) =>
    dispatch({
      type: "user.schoolTeachers.success",
      payload: data,
    }),
  );
};

const transformUserInfo = (userInfo) => ({
  ...userInfo,
  user: {
    ...userInfo.user,
    type: {
      1: "student",
      2: "teacher",
      3: "employee",
      4: "manager",
      5: "admin",
    }[userInfo.user.type],
  },
});
export const getUserInfo = (userSchool) => (dispatch) => {
  dispatch({ type: "user.isLoading" });
  api.getUserInfo(userSchool).then((response) =>
    dispatch({
      type: "user.infoLoaded",
      payload: transformUserInfo(response.data),
    }),
  );
};

export const loadDefaultSubjects = () => (dispatch) => {
  api.getDefaultSubjects().then((subjects) =>
    dispatch({
      type: "user.defaultSubjectsLoaded",
      payload: subjects,
    }),
  );
};

export const logout = (keepUserRecord) => (dispatch, getState) => {
  return api
    .logoutSso()
    .then(() => {
      delete api.instance.defaults.headers.common["Authorization"];
      localStorage.removeItem("user.school");
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      window.clearInterval(getState().user.refreshIntervalId);
      if (getState().router.location.pathname !== "/Login") {
        dispatch(push("/Login"));
      }
      if (!keepUserRecord) {
        localStorage.removeItem("old_access_token");
        localStorage.removeItem("old_codigo_usuario");
        dispatch({
          type: "user.logout",
        });
      }
    })
    .catch(() => {
      delete api.instance.defaults.headers.common["Authorization"];
      localStorage.removeItem("user.school");
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      window.clearInterval(getState().user.refreshIntervalId);
      if (!keepUserRecord) {
        localStorage.removeItem("old_access_token");
        localStorage.removeItem("old_codigo_usuario");
        dispatch({
          type: "user.logout",
        });
      }
      if (getState().router.location.pathname !== "/Login") {
        dispatch(push("/Login"));
      }
    });
};
