import ClickButton from "components/ClickButton/ClickButton";
import ClickHeading from "components/ClickHeading/ClickHeading";
import ClickLink from "components/ClickLink/ClickLink";
import { ThemeContext } from "contexts/Theme.context";
import { UserContext } from "contexts/User.context";
import { history } from "core";
import { instance } from "core/api";
import { Field, Form, Formik, FormikActions } from "formik";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import styles from "./styles/PasswordReset.module.scss";

const resetPasswordSchema = z
  .object({
    password: z
      .string({ required_error: "Este campo é obrigatório" })
      .min(6, "A senha deve ter no mínimo 6 caracteres"),
    confirm: z.string({ required_error: "Este campo é obrigatório" }),
  })
  .refine((data) => data.password === data.confirm, {
    message: "As senhas devem ser iguais",
    path: ["confirm"],
  });

type ResetPasswordFormData = z.infer<typeof resetPasswordSchema>;

export function PasswordReset(): JSX.Element {
  const { setMainBackground } = useContext(ThemeContext);
  const { search } = useLocation<{ token: string }>();
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  // Handle authenticated user
  const { id } = useContext<{ id: string }>(UserContext);

  useEffect(() => {
    const token = new URLSearchParams(search).get("token");
    const email = new URLSearchParams(search).get("email");

    if (id || !token || !email) history.push("/");
  }, [id, search]);

  useEffect(() => {
    if (setMainBackground) setMainBackground(false);
  }, [setMainBackground]);

  async function handleSubmit(
    values: ResetPasswordFormData,
    actions: FormikActions<ResetPasswordFormData>,
  ) {
    try {
      const response = await instance.post("/password/reset", {
        password: values.password,
        password_confirmation: values.confirm,
        token: new URLSearchParams(search).get("token"),
        email: new URLSearchParams(search).get("email"),
      });

      if (response.status !== 200)
        toast("Algo inexperado aconteceu", {
          type: "warning",
        });

      toast("Sua senha foi redefinida com sucesso", {
        type: "success",
        onClose: () => {
          window.location.replace(`${process.env.REACT_APP_PORTAL_URL}/login/`);
        },
      });

      setIsCompleted(true);
    } catch (error: any) {
      if (!error.response || !error.response?.data) {
        toast("Não foi possível redefinir sua senha", {
          type: "error",
        });
        return;
      }

      toast("Não foi possível redefinir sua senha", {
        type: "error",
      });
    }

    actions.setSubmitting(false);
  }

  return isCompleted ? (
    <>
      <div className={styles.container}>
        <div className={styles.resetPasswordContainer}>
          <ClickHeading tag="h1">
            Redefina sua <span>senha</span>
          </ClickHeading>

          <p style={{ textAlign: "center" }}>
            Sua senha foi redefinida com sucesso. Você será redirecionado para a
            tela de login.
          </p>

          <ClickLink
            className={styles.loginLink}
            link={`${process.env.REACT_APP_PORTAL_URL}/login/`}
          >
            Login
          </ClickLink>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className={styles.container}>
        <div className={styles.resetPasswordContainer}>
          <ClickHeading tag="h1">
            Redefina sua <span>senha</span>
          </ClickHeading>

          <Formik
            initialValues={{ password: "", confirm: "" }}
            validationSchema={toFormikValidationSchema(resetPasswordSchema)}
            onSubmit={handleSubmit}
          >
            {({ errors }) => (
              <Form>
                <div className={styles.inputGroup}>
                  <label className={styles.inputLabel} htmlFor="password">
                    Senha
                  </label>
                  {errors.password && (
                    <span className={styles.error}>{errors.password}</span>
                  )}
                  <Field
                    className={styles.input}
                    type="password"
                    name="password"
                  />
                </div>

                <div className={styles.inputGroup}>
                  <label className={styles.inputLabel} htmlFor="password">
                    Confirmar senha
                  </label>
                  {errors.confirm && (
                    <span className={styles.error}>{errors.confirm}</span>
                  )}
                  <Field
                    className={styles.input}
                    type="password"
                    name="confirm"
                  />
                </div>

                <ClickButton
                  className={styles.submitButton}
                  submit={true}
                  color="success"
                >
                  Redefinir
                </ClickButton>

                <ClickLink className={styles.loginLink} link="/Login">
                  Login
                </ClickLink>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
