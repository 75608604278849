import ClickModal from "components/ClickModal";
import { ClickTextInput } from "components/Form";
import { Formik } from "formik";
import { connect } from "react-redux";
import { vincularEmail } from "./EmailBinding.actions";

const EmailBindingModal = ({ setIsModalOpen, email_vinculed_before, vincularEmail }) => {
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await vincularEmail(values.email, values.email_confirmation);
      setIsModalOpen(false);
    } catch (error) {
      console.log("Erro ao vincular o e-mail:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        email: email_vinculed_before || "",
        email_confirmation: email_vinculed_before || "",
      }}
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <ClickModal
          onClose={() => setIsModalOpen(false)}
          buttons={[
            {
              children: "Fechar",
              fill: "ghost",
              onClick: () => setIsModalOpen(false),
            },
            {
              children: "Salvar",
              type: "submit",
              color: "success",
              onClick: formikProps.submitForm,
              disabled: !formikProps.isValid || formikProps.isSubmitting,
            },
          ]}
        >
          <div style={{ padding: '0 20px' }}>
            <h2 style={{ marginBottom: '1rem', fontWeight: 'bold' }}>Vinculação de E-mail</h2>
            <p style={{ lineHeight: '1.5' }}>
              Para se autenticar com seu e-mail Google, você precisa vinculá-lo à sua conta.
            </p>
            <p style={{ marginBottom: '1.5rem', lineHeight: '1.5' }}>
              Preencha o campo abaixo com seu e-mail Google e repita no campo seguinte para confirmação.
            </p>
            <ClickTextInput
              id="email"
              name="email"
              label="E-mail"
              withFormik
              style={{ marginBottom: '1rem' }}
            />
            <ClickTextInput
              id="email_confirmation"
              name="email_confirmation"
              label="Confirme o E-mail"
              withFormik
              style={{ marginBottom: '1rem' }}
            />
          </div>
        </ClickModal>
      )}
    </Formik>
  );
};

const mapDispatchToProps = {
  vincularEmail,
};

export default connect(null, mapDispatchToProps)(EmailBindingModal);
