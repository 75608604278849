import { library as fontawesome } from "@fortawesome/fontawesome-svg-core";
import { fab as brands } from "@fortawesome/free-brands-svg-icons";
import { far as regular } from "@fortawesome/free-regular-svg-icons";
import { fas as solid } from "@fortawesome/free-solid-svg-icons";
import { ConnectedRouter, routerMiddleware } from "connected-react-router";
import createBrowserHistory from "history/createBrowserHistory";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import Routes from "views/Routes";
import api from "./api";
import coreReducer from "./core.reducer";
import { tryToLoadTokens } from "./user.actions";

export * from "./utils";

fontawesome.add(brands, solid, regular);

export { api };

// history to handle browser's navigation
export const history = createBrowserHistory();

// Middleware for when an redux navigation action is fired -> middleware fires a
//  history.push to make that action happens
const middlewares = [routerMiddleware(history), thunk];

// compose from redux or redux_devtools, to join middlewares
const compose_with_devtools =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// createStore from redux, with the devtools enhancer
// here, the store initial state (second param) is ommited.
export const rootStore = createStore(
  coreReducer(history),
  compose_with_devtools(applyMiddleware(...middlewares))
);

export default (
  <>
      <Provider store={rootStore}>
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
      </Provider>
      <ToastContainer />
  </>
);

rootStore.dispatch(tryToLoadTokens());
